<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-body">
        <div class="text">
          <b-row>
            <b-col md="4">
              <select class="form-control"  @change="selectBot">
                <option>Selectionnez votre bot</option>
                <option v-for="(b,i) in bots" :key="i" :value="b.id">{{b.name}}</option>
              </select>
            </b-col>
            <b-col md="2">
              <b-form-group label-for="input-1">
                <b-form-input v-if="botSelected.id != null" v-model="botSelected.name" type="text"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="2" offset-md="4" style="text-align: right;">
              <a class="btn btn-icon btn-sm btn-danger mr-1" v-if="botSelected.id != null" @click="deleteBot(botSelected.id)">
                <i class="icon-md fas fa-trash"></i>
              </a>
              <a class="btn btn-icon btn-sm btn-info mr-1" v-if="botSelected.id != null" @click="cloneBot(botSelected.id)">
                <i class="icon-md fas fa-clone"></i>
              </a>
              <a class="btn btn-icon btn-sm btn-primary mr-1" v-b-modal.modal-1>
                <i class="icon-md fas fa-plus"></i>
              </a>
              <a class="btn btn-icon btn-sm btn-success mr-1" @click="save()">
                <i class="icon-md fas fa-save"></i>
              </a>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
    <b-row>
      <b-col md="5">
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Steps</h3>
            </div>
            <div class="card-toolbar" v-if="botSelected.id != null">
              <b-dropdown size="sm" variant="primary" html='<i class="icon-md fas fa-plus"></i>'>
                <b-dropdown-item @click="addStep('message')">Message</b-dropdown-item>
                <b-dropdown-item @click="addStep('text')">Text</b-dropdown-item>
                <b-dropdown-item @click="addStep('button')">Button</b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
          <div class="card-body">
            <b-list-group>
              <draggable v-model="botSelected.steps" group="people" @start="drag=true" @end="drag=false" @change="majStepsPosition">
                <b-list-group-item v-for="step in botSelected.steps" :key="step.step" @click="selectStep(step.step)" :class="stepSelected.step === step.step ? 'active' : '' ">
                  <div class="row">
                    <div class="col-10">
                      <i class="fas fa-stream"></i>
                      <span class="text ml-5">
                        step: <strong>{{step.step}}</strong>
                      </span>
                      <span class="text ml-5">
                        type: <strong>{{step.type}}</strong>
                      </span>
                      <span class="text ml-5" v-if="step.type === 'action'">
                        metod: <strong>{{step.metod}}</strong>
                      </span>
                    </div>
                    <div class="col-1">
                      <a class="btn btn-icon btn-sm btn-danger mr-1 ml-3" @click="deleteStep(step.step)">
                        <i class="icon-md fas fa-trash"></i>
                      </a>
                    </div>
                  </div>
                </b-list-group-item>
              </draggable>
            </b-list-group>
          </div>
        </div>
      </b-col>
      <b-col md="7">
        <div class="card card-custom gutter-b sticky-top">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Detail de la Step</h3>
            </div>
            <div class="card-toolbar" >
              <a class="btn btn-icon btn-sm btn-primary mr-1" @click="addCondition()" v-if="stepSelected.type === 'action'">IF</a>
              <a class="btn btn-icon btn-sm btn-primary mr-1" @click="addAction()" v-if="stepSelected.type === 'action' && stepSelected.metod === 'button'">AC</a>
            </div>
          </div>
          <div class="card-body">
            <div v-if="stepSelected.step != null">
              <b-list-group>
                <b-list-group-item>
                  <span>Step:</span>
                  <span class="ml-3">
                    <input v-model="stepSelected.step" style="important;font-weight: bold;" disabled/>
                  </span>
                </b-list-group-item>
                <b-list-group-item>
                  <span>Type:</span>
                  <span class="ml-3">
                    <select v-model="stepSelected.type" style="important;font-weight: bold;" disabled>
                      <option value="message">message</option>
                      <option value="action">action</option>
                    </select>
                  </span>
                </b-list-group-item>
                <b-list-group-item>
                  <span>Metod:</span>
                  <span class="ml-3">
                    <select v-model="stepSelected.metod" style="important;font-weight: bold;" disabled>
                      <option value="bot" v-if="stepSelected.type === 'message'">bot</option>
                      <option value="text" v-if="stepSelected.type === 'action'">text</option>
                      <option value="button" v-if="stepSelected.type === 'action'">button</option>
                    </select>
                  </span>
                </b-list-group-item>
                <b-list-group-item>
                  <span>Delay:</span>
                  <span class="ml-3">
                    <input v-model="stepSelected.param.delay" style="important;font-weight: bold;"/>
                  </span>
                </b-list-group-item>
                <b-list-group-item>
                  <div class="row">
                    <div class="col-sm-2">
                      Loading:
                    </div>
                    <div class="col-sm-9">
                      <b-form-checkbox v-model="stepSelected.param.loading" size="lg"></b-form-checkbox>
                    </div>
                  </div>
                </b-list-group-item>
                <b-list-group-item>
                  <span>cssClass:</span>
                  <span class="ml-3">
                    <input v-model="stepSelected.param.cssClass" style="important;font-weight: bold;"/>
                  </span>
                </b-list-group-item>
                <b-list-group-item v-if="stepSelected.type === 'message'">
                  <span>Content:</span>
                  <span class="ml-3">
                    <input v-model="stepSelected.param.content" style="important;font-weight: bold;"/>
                  </span>
                </b-list-group-item>
                <b-list-group-item v-if="stepSelected.type === 'action' && stepSelected.metod === 'text'">
                  <span>Placeholder:</span>
                  <span class="ml-3">
                    <input v-model="stepSelected.param.action.placeholder" style="important;font-weight: bold;"/>
                  </span>
                </b-list-group-item>
                <div v-if="stepSelected.type === 'action' && stepSelected.metod === 'button'">
                  <b-list-group-item  v-for="(a,i) in stepSelected.param.action" :key="i">
                    <span>Action:</span>
                    <span class="ml-3">Text:</span>
                    <span class="ml-3">
                      <input v-model="a.text" style="max-width: 80px !important;font-weight: bold;"/>
                    </span>
                    <span class="ml-3">|</span>
                    <span class="ml-13">Value:</span>
                    <span class="ml-3">
                      <input v-model="a.value" style="max-width: 70px !important;font-weight: bold;"/>
                    </span>
                    <span class="ml-1">|</span>
                    <a class="btn btn-icon btn-sm btn-danger mr-1 ml-3" @click="deleteAction(i)">
                      <i class="icon-md fas fa-trash"></i>
                    </a>
                  </b-list-group-item>
                </div>
              </b-list-group>
            </div>
            <div v-if="stepSelected.type === 'action'">
              <b-list-group-item>
                <span>Key:</span>
                <span class="ml-3">
                  <input v-model="stepSelected.param.key" style="max-width: 70px !important;font-weight: bold;"/>
                </span>
              </b-list-group-item>
              <div v-if="stepSelected.param.conditions.length > 0">
                <b-list-group-item v-for="(c,i) in stepSelected.param.conditions" :key="i">
                  <span>Condition:</span>
                  <span class="ml-1">key: </span>
                  <span class="ml-1">
                    <input v-model="c.key" style="max-width: 70px !important;font-weight: bold;"/>
                  </span>
                  <span class="ml-1">|</span>
                  <span class="ml-1">operateur: </span>
                  <span class="ml-1">
                    <select v-model="c.operateur" class="" style="font-weight: bold;">
                      <option value="">Operateur</option>
                      <option value="="> = </option>
                      <option value=">"> > </option>
                      <option value="<"> &lt; </option>
                      <option value="!="> != </option>
                    </select>
                  </span>
                  <span class="ml-1">|</span>
                  <span class="ml-1">valeur: </span>
                  <span class="ml-1">
                    <input style="width: 60px;font-weight: bold;" v-model="c.valeur" type="text"/>
                  </span>
                  <span class="ml-1">|</span>
                  <span class="ml-1">StepReturn: </span>
                  <span class="ml-1">
                    <input style="width: 35px;font-weight: bold;" v-model="c.stepReturn" type="number"/>
                  </span>
                  <span class="ml-1">|</span>
                  <a class="btn btn-icon btn-sm btn-danger mr-1 ml-1" @click="deleteCondition(i)">
                    <i class="icon-md fas fa-trash"></i>
                  </a>
                </b-list-group-item>
              </div>
            </div>
            <b-list-group-item v-if="stepSelected.step != null">
              <div class="form-group">
                <label>function():</label>
                <textarea class="form-control form-control-lg mb-3" v-model="stepSelected.param.function">
                </textarea>
              </div>
            </b-list-group-item>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-modal id="modal-1" title="Nouveau Bot" ref="modal1">
      <b-form-group label="Titre">
        <b-form-input v-model="tmpBot.name" placeholder="Titre du Bot"></b-form-input>
      </b-form-group>
      <template #modal-footer="{ ok, cancel, hide }">
        <b-button variant="info" @click="cancel()">
          Annuler
        </b-button>
        <b-button variant="primary" @click="addBot()">
          Ajouter
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import draggable from 'vuedraggable'

export default {
  data(){
    return {
      tmpBot:{
        'name':null
      },
      bots:[],
      botSelected:{
        id:null,
        name:null,
        steps:[]
      },
      stepSelected:{
        type: null,
        metod: null,
        param: {
          type: null,
          delay: null,
          cssClass: null,
          content: null,
          conditions:[]
        },
        step: null
      },
      stepTmp:{
        message:{
          type: "message",
          metod: "bot",
          param: {
            delay: 500,
            loading: true,
            content: null
          },
          step: null
        },
        actions:{
          text:{
            type: "action",
            metod: "text",
            param: {
              delay: 500,
              loading: true,
              action: {
                placeholder: null
              },
              key: null,
              function: "",
              conditions: []
            },
            step: null,
            key: null
          },
          button:{
            type: "action",
            metod: "button",
            param: {
              delay: 500,
              action: [],
              loading: true,
              key: null,
              function: null,
              conditions: []
            },
            step: null
          }
        }
      },
      objs:['nom','email','telephone','mois']
    };
  },
  components:{
    draggable,
  },
  watch: {
  },
  methods: {
    addBot(){
      this.$store.back.ajax('/bot/create', this.tmpBot, (res) => {
        if(res.status == true){
          this.bots.push(res.data);
          this.botSelected = res.data;
          this.$refs.modal1.hide();
        }
      });
    },
    selectBot(event){
      var id = event.target.value
      this.botSelected = this.bots.find(b => b.id == id);
    },
    selectStep(id){
      this.stepSelected = this.botSelected.steps.find(s => s.step == id);
    },
    deleteStep(i){
      this.botSelected.steps = this.botSelected.steps.filter(s => s.step != i);
      this.majStepsPosition();
      console.log(this.botSelected.steps)
    },
    majStepsPosition(){
      this.botSelected.steps.forEach((step, i) => {
        step.step = i;
      });
    },
    addCondition(){
      this.stepSelected.param.conditions.push({
        key:"",
        operateur:"",
        valeur:"",
        stepReturn:""
      });
    },
    deleteCondition(i){
      this.stepSelected.param.conditions.splice(i,1);
    },
    addAction(){
      this.stepSelected.param.action.push({
        text:"",
        value:""
      });
    },
    deleteAction(i){
      this.stepSelected.param.action.splice(i,1);
    },
    save(){
      this.$store.back.ajax('/bot/update', this.botSelected, (res) => {
        if(res.status === true){
          this.$bvToast.toast(`Votre bot est bien sauvegarde !`, {
            title: 'Sauvegarder !',
            variant: 'success',
            solid: true
          })
        }
      });
    },
    cloneBot(id){
      this.$store.back.ajax(`/bot/clone/${id}`, null, (res) => {
        if(res.status === true){
          this.$bvToast.toast(`Votre bot a bien ete clone !`, {
            title: 'Sauvegarder !',
            variant: 'success',
            solid: true
          });
          this.bots.push(res.data);
        }
      });
    },
    deleteBot(id){
      this.$store.back.ajax(`/bot/delete/${id}`, null, (res) => {
        if(res.status === true){
          this.$bvToast.toast(`Votre bot a bien ete supprimer !`, {
            title: 'Sauvegarder !',
            variant: 'success',
            solid: true
          });
          this.bots = res.data;
        }
      });
    },
    addStep(type){
      var step = {};
      switch (type) {
        case 'message':
        step = this.stepTmp.message;
        break;
        case 'text':
        step = this.stepTmp.actions.text;
        break;
        case 'button':
        step = this.stepTmp.actions.button;
        break;
        default:
        return false;
      }
      step = JSON.stringify(step);
      step = JSON.parse(step);
      this.botSelected.steps.push(step);
      this.majStepsPosition();
    }
  },
  computed: {
  },
  beforeMount() {
    this.$store.back.ajax('/bot/liste', null, (res) => {
      if(res.status === true){
        this.bots = res.data;
      }
    });
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vue Bootstrap", route: "alert" },
      { title: "" }
    ]);
  }
};
</script>
